

import React from 'react';
import '../assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
function Footer() {

    return (
        <div className="footer">
                <div className="copyright">
                    <p >Copyright © Designed & Developed by <a style={{textDecoration: "none", color: "#e58f00"}} href="https://corpteamsolution.com/" target="_blank">CorpTeam Solutions</a> <span className="current-year">2024</span></p>
                </div>
            </div>
    );
}

export default Footer;
