import React from "react";


const Error = () => {

  return (
    <p>404 Error</p>
  );
}

export default Error;
